import http from "../axios/api";
import { createFormData } from "@/utils.js";

// const _host =  process.env.NODE_ENV=="development" ?  '/api':  'http://xiaoguoai.cn/api'; //'http://qo.bzhuan888.com'
// console.log(_host)
// http://xiaoguoai.cn/api/ht/login?username='主页'&password='123456'&access_token=

export async function getNotice(data) {
  return http.post(`/system/notice/search`, data);
}
// 查询
export function getHelp(data) {
  return http.post(`/help/findHelp`, data);
}

//新闻列表
export function getNewsList(data) {
  return http.post(`/news/homePageNewsList`, data);
}
//新闻列表
export function gethomePageNewsDetail(data) {
  return http.post(`/news/homePageNewsDetail`, data);
}
//新闻内容的阅读量
export function getCount(data) {
  return http.post(`/news/newsCount/${data.id}`);
}

//获取验证码
export function getCode(param) {
  return http.post(`/wechat/applet/send/code`, param);
}
//发送客户信息
export function remberRemark(param) {
  return http.post(`wechat/applet/remberRemark`, param);
}
//
export function consultQuestion(param) {
  return http.post(`admin/addIntentionalCustomer`, param);
}
//登录
export function XGShoppingLogin(param) {
  return http.post(`XGShopping/login?username=${param.username}&password=${param.password}`);
}
//注册
export function XGShoppingRegister(param) {
  return http.post(`/XGShopping/registerOrUpDate`,param);
}
//查询购物车
export function XGShoppingSelectCart() {
  return http.get(`/XGShopping/selectCart`);
}
// 查询商品 /XGShopping/selectShopping name  type  proid page pageSize
export function XGShoppingSelectShopping(param) {
  return http.get(`/XGShopping/selectShopping?name=${param.name || ''}&type=${param.type|| ''}&pageSize=${param.size|| ''}&proid=${param.proid|| ''}&page=${param.page|| ''}`);
}
// set购物车 /XGShopping/addCart proid add  cut  createTime
export function XGShoppingAddCart(param) {
  return http.post(`/XGShopping/addCart`, param);
}
// del购物车 /XGShopping/deleteCart proid
export function XGShoppingDeleteCart(param) {
  return http.post(`/XGShopping/deleteCart?proIds=${param.proids}`);
}
// 查询地址 /XGShopping/selectAddress proid
export function XGShoppingSelectAddress(param) {
  return http.get(`/XGShopping/selectAddress`);
}
// 新增/修改地址 /XGShopping/addorUpdateAddress id有则修改 无则新增 receivername  receivermobile receiverprovince receivercity receiverdistrict receiveraddress
export function XGShoppingAddorUpdateAddress(param) {
  return http.post(`/XGShopping/addorUpdateAddress`,param);
}
// 删除地址 /XGShopping/addorUpdateAddress id
export function XGShoppingDeleteAddress(param) {
  return http.post(`/XGShopping/deleteAddress?addId=${param.id || ''}`);
}
// 提交订单 /XGShopping/addUserOrder id+ 商品数组 orderItems
export function XGShoppingAddUserOrder(params) {
  return http.post(`/XGShopping/addUserOrder?addId=${params.addId}`, {orderItems:params.orderItems});
}
// 获取二维码
export function nativePay() {
  return http.post({
      url: `/XGShopping/nativePay`,
      method: 'post',
      responseType: "blob"
  });
}
// 轮询订单
export function selectNativePay(orderId) {
  return http.get(`/XGShopping/selectNativePay/${orderId}`);
}

// 查询订单 /XGShopping/selectOrder 
export function XGShoppingSelectOrder(params) {
  return http.get(`/XGShopping/selectOrder`, params);
}
